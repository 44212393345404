import React from "react";
import "./style.scss";
import Hero from "./components/hero/Hero";
import List from "./components/list/List";
import List2 from "./components/list/List2";
import Sidebarone from "./components/sidebar/Sidebarone";
import Bigsection from "./components/bigsection/Bigsection";
import Sidebar from "../main/sidebar/Sidebar";

const Home = ({ data }) => {
  let feturedStory = data?.cms?.recent_story.filter(checkFetured);
  let feturedList = data?.cms?.recent_listicle.filter(checkFetured);

  function checkFetured(element) {
    return element.is_feature_post;
  }
  return (
    <div className="home-1">
      <div className="container is-max-widescreen mt-2">
        <Hero data={feturedStory} />
        <div className="columns m-0">
          <div className="column is-two-thirds">
            <List data={feturedList} />
          </div>
          <div className="column mt-6 mr-3 ml-3">
            <Sidebarone data={feturedStory} />
          </div>
        </div>
      </div>
      <Bigsection data={data} />
      <div className="container is-max-widescreen mt-2">
        <div className="columns m-0">
          <div className="column is-two-thirds">
            <List2 data={feturedStory} />
          </div>
          <div className="column">
            <Sidebar recent_story={feturedList} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
