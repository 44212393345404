import React from "react";
import "./style.scss";
import { Link } from "gatsby";

const Sidebarone = ({ data }) => {
  const list = data ? [data[11], data[5]] : [];
  function addStr(str, index, stringToAdd) {
    if (!str) {
      str = "";
      index = 0;
    }
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  }
  return (
    <div className="tile is-ancestor">
      <div className="tile is-vertical">
        {list.map((element) => {
          return (
            <div className="tile hfs-1">
              {" "}
              <Link to={`/${element?.slug}`}>
                <article
                  className="tile is-child hfs-1"
                  style={{
                    backgroundImage: `url(${element?.image})`,
                  }}
                >
                  <div className="hfs-details">
                    <div className="title">
                      {element?.title}
                      <div className="category">
                        <span>{element?.categories[0]?.name}</span>
                      </div>
                    </div>
                  </div>
                </article>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Sidebarone;
