import React, { useState } from "react";
import "./style.scss";
import { Link } from "gatsby";

const List2 = ({ data }) => {
  const list = [];
  const [count, setCount] = useState(6);

  for (let index = 0; index < count; index++) {
    const element = data[index];
    element && list.push(element);
  }

  function addStr(str, index, stringToAdd) {
    if (!str) {
      str = "";
      index = 0;
    }
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  }

  const truncate = (str, max, suffix) => {
    // ReactHtmlParser(str);
    return str.length < max ? str : `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(" "))}${suffix}`;
  };

  return (
    <div className="home-list">
      {/* <pre>{JSON.stringify({ list, rrr: data }, null, 2)}</pre> */}
      <div className="list">
        <div className="list-head">
          <span>Recent Stories</span>
        </div>
        {list.map((element) => {
          return (
            <Link to={`/${element?.slug}`}>
              <div className="list-item">
                <div className="list-item-image">
                  <figure className="image hl-image">
                    <div className="ratio-img ratio-66">{element?.image?.includes("idc") ? <img src={element?.image} alt={element.title} /> : <img src={element?.image} alt={element.title} />}</div>
                  </figure>
                </div>

                <div className="list-item-content">
                  <div className="list-item-title">{element?.title}</div>
                  <div className="list-item-category">
                    <span>{element?.categories[0]?.name}</span>
                  </div>
                  <div className="list-item-description">{truncate(element?.content.replace(/<[^>]+>/g, ""), 150, "...")}</div>
                </div>
              </div>
            </Link>
          );
        })}
        <div className="home-button">
          <button className="button is-medium is-fullwidth" onClick={() => setCount(count + 6)}>
            More Items
          </button>
        </div>
      </div>
    </div>
  );
};
export default List2;
