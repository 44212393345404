import React from "react";
import "../pages/mystyles.scss";
import Header from "../components/header/Header";
import Home from "../components/home/Home";
import Footer from "../../../../components/footer/Footer";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

const IndexPage = (props) => {
  const { data } = props;
  const { pageContext } = props;
  const { postSearchData } = pageContext;
  const { allPosts, options } = postSearchData;
  return (
    <div className="layout">
      <Helmet>
        <meta name="d103bf43fb93b7f" content="c0b9cd4d499846b447bed15f2e787549" />
      </Helmet>
      <Header domain={data?.cms?.domain} category={data?.cms?.categories} title={data?.cms.recent_story.title} allPosts={allPosts} engine={options} />
      <Home data={data} />
      <Footer pages={data?.cms?.pages} domain={data?.cms?.domain} email={"editor@thenerdseries.com"} />
    </div>
  );
};
export default IndexPage;

export const query = graphql`
  query home {
    cms {
      domain: CMS_Domain(id: "1") {
        id
        logo
        logo_light
        domain_url
        name
        description
        favicon
        header_script
      }
      categories: CMS_CategoryByDomain(domain_id: "1") {
        name
        slug
      }
      tech_listicle: CMS_DomainWiseLists(domain_id: 1, category_id: "5") {
        title
        image
        slug
        is_feature_post
        categories {
          name
        }
      }
      tech_story: CMS_DomainWiseStories(domain_id: "1", category_id: "5") {
        image
        slug
        title
        is_feature_post
        categories {
          name
        }
      }
      recent_listicle: CMS_DomainWiseLists(domain_id: "1") {
        title
        is_feature_post
        image
        slug
        description
        categories {
          name
        }
      }
      recent_story: CMS_DomainWiseStories(domain_id: "1") {
        image
        slug
        title
        content
        is_feature_post
        categories {
          name
        }
      }
      pages: Published_Pages(domain_id: "1") {
        slug
        title
      }
    }
  }
`;
